import styled from '@emotion/styled'
import { IBEButton } from 'app/components/Common/Button/IBEButton'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  IBEID?: number
  languageCode: string
  languagePrefix?: string | null
  services: string[]
  title?: string
}

export const RoomOtherServices = memo(function RoomOtherServices({
  IBEID,
  languageCode,
  languagePrefix,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {IBEID ? (
          <CTA
            IBEID={IBEID}
            languageCode={languageCode}
            languagePrefix={languagePrefix}
          />
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <FadeInUp>
          <Services row wrap>
            {services.map((item: any, index) => (
              <Service key={index}>{item.label}</Service>
            ))}
          </Services>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-bottom: 4.375rem;
  padding: 0 6.667vw;

  @media (max-width: 1023px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.secondaryDark2};
  padding: 6.25rem 3.333vw;
  position: relative;

  @media (max-width: 1023px) {
    padding: 5rem 1.875rem;
  }
`

const CTA = styled(IBEButton)`
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight3};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  text-transform: uppercase;
`

const Services = styled(FlexBox)`
  max-width: 64rem;
  margin: 1.875rem auto 0;

  @media (max-width: 1199px) {
    max-width: none;
    transform: none;
  }
`

const Service = styled.div`
  width: 33.333%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 1.875rem;
  padding: 0 2rem;
  position: relative;
  text-align: left;
  &:before {
    content: '';
    width: 1.25rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight3};
    position: absolute;
    top: 1rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`
