import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Head } from './Head'
import { Props as ServiceProps, Service } from './Service'

export interface Props {
  description?: string
  pax?: string
  servicesTitle?: string
  services?: ServiceProps[]
  size?: string
  title?: string
}

export const RoomIntro = memo(function RoomIntro({
  description,
  pax,
  servicesTitle,
  services,
  size,
  title,
}: Props) {
  return (
    <Container>
      <Head pax={pax} size={size} title={title} />

      <Wrapper row stretch wrap>
        <LeftSide>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </LeftSide>
        <RightSide>
          {servicesTitle ? (
            <FadeInUp>
              <ServicesTitle>{servicesTitle}</ServicesTitle>
            </FadeInUp>
          ) : null}
          {services ? (
            <FadeInUp>
              <Services row wrap>
                {services.map((item, index) => (
                  <Service key={index} {...item} />
                ))}
              </Services>
            </FadeInUp>
          ) : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
`

const LeftSide = styled.div`
  width: 50%;
  padding: 4.875rem 8.125vw 7.5rem 6.667vw;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding: 3.75rem 1.875rem 5.625rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  border-left: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 4.875rem 8.125vw 7.5rem;

  @media (max-width: 1023px) {
    width: 100%;
    border-left: 0;
    padding: 3.75rem 1.875rem 0;
    text-align: center;
  }
`

const ServicesTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-transform: uppercase;
`

const Services = styled(FlexBox)`
  @media (max-width: 1023px) {
    justify-content: center;
  }
`
