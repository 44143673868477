import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  category?: string
  className?: string
  image?: ImageProps
  pax?: string
  size?: string
  title: string
  URL: string
}

export const Slide = memo(function Slide({
  category,
  className,
  image,
  pax,
  size,
  title,
  URL,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container className={className} to={URL}>
      <Background className="slide-image">
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
      <Title>{category ? `${category} ${title}` : title}</Title>
      {pax || size ? (
        <Info row>
          {size ? <Size>{size}</Size> : null}
          {pax ? <Pax>{pax}</Pax> : null}
        </Info>
      ) : null}
    </Container>
  )
})

const Container = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  text-align: left;
  &:hover {
    .slide-image {
      &:before {
        opacity: 0.6;
      }
      img {
        transform: scale(1.1);
      }
    }
  }
`

const Background = styled.div`
  padding-bottom: 75%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 60%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 1)}
    );
    opacity: 0.3;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.25s ease-in-out;
    z-index: 2;
  }

  img {
    width: 101%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: 0.3s ease-in-out !important;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0094rem;
  line-height: 1.1875rem;
  margin-top: 1.375rem;
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin-top: 0.5625rem;
  padding-left: 1.5rem;
  &:before {
    content: '';
    width: 1rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 0.5625rem;
    left: 0;
  }

  > span {
    &:nth-of-type(2) {
      &:before {
        content: '|';
        margin: 0 0.5625rem;
      }
    }
  }
`

const Pax = styled.span``

const Size = styled.span``
